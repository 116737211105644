var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" 冰山數據 ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" 成为我们的合作伙伴 🤝 ")]),_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body font-small-2"},[_c('p',[_c('small',{staticClass:"mr-50"},[_vm._v("注册账号申请成为冰山數據合作伙伴")])])])]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"密码"}},[_c('validation-provider',{attrs:{"name":"密码","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"register-v-code"}},[_vm._v("验证码")]),_c('b-link',{attrs:{"disabled":_vm.vCodeSent},on:{"click":function($event){return _vm.sendVCode()}}},[_c('small',[_vm._v(_vm._s(_vm.vCodeText))])])],1),_c('validation-provider',{attrs:{"name":"验证码","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-v-code","name":"register-v-code","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.userData.vcode),callback:function ($$v) {_vm.$set(_vm.userData, "vcode", $$v)},expression:"userData.vcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"user-type","label":"合作形式"}},[_c('validation-provider',{attrs:{"name":"合作形式","vid":"user-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (val) { return val.value; },"clearable":true,"input-id":"user-type"},model:{value:(_vm.userData.type),callback:function ($$v) {_vm.$set(_vm.userData, "type", $$v)},expression:"userData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isSaving},on:{"click":function($event){$event.preventDefault();return _vm.register()}}},[_vm._v(" 注册 ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('small',[_vm._v("已经有账号了？ ")]),_c('b-link',{attrs:{"to":{name:'auth-login-partner'}}},[_c('small',[_vm._v(" 去登录")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }