<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          冰山數據
        </h2>
      </b-link>


      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>

    
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            成为我们的合作伙伴 🤝
          </b-card-title>

          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50">注册账号申请成为冰山數據合作伙伴</small>
              </p>
            </div>
          </b-alert>
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userData.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="register-password"
                label="密码"
              >
                <validation-provider
                  #default="{ errors }"
                  name="密码"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="userData.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
              <div class="d-flex justify-content-between">
                  <label for="register-v-code">验证码</label>
                   <b-link @click="sendVCode()" :disabled="vCodeSent">
                    <small>{{ vCodeText }}</small>
                   </b-link> 
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="验证码"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="register-v-code"
                    v-model="userData.vcode"
                    name="register-v-code"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
              label-for="user-type"
                label="合作形式">
                <validation-provider
                  #default="{ errors }"
                  name="合作形式"
                  vid="user-type"
                  rules="required"
                >
                <v-select
                  v-model="userData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="true"
                  input-id="user-type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>

              <b-button
                @click.prevent="register()"
                variant="primary"
                block
                type="submit"
                :disabled="isSaving"
              >
                注册
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <small>已经有账号了？ </small>
            <b-link :to="{name:'auth-login-partner'}">
              <small>&nbsp;去登录</small>
            </b-link>
          </p>

      
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BFormRadio,BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import vSelect from 'vue-select'


export default {
  components: {
    BAlert,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      typeOptions:[
        {value:'company',label:'公司'},
        {value:'individual',label:'个人兼职'},
      ],
      vCodeText: '发送验证码',
      vCodeSent: false,
      isSaving:false,
      userData:{
        type:'individual',
        email:'',
        password:'',
        vcode:'',
      },
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    sendVCode(){
      if (!this.userData.email){
        this.$toast.error('请先输入Email')
        return
      }
      this.vCodeSent = true
      let count = 30
      let timer = setInterval(()=>{
        count--
        this.vCodeText = count + ''
        if (count == 0){
          clearInterval(timer)
          this.vCodeSent = false
          this.vCodeText = '发送验证码'
        }
      },1000)
      this.$axios.get('/partner/register_vcode/' + this.userData.email).then(resp=>{
        if(resp.data.status == "ok"){
          this.$toast.success('验证码已发送')
        }
      })
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.isSaving = true;
          this.$axios.post('/partner/register',this.userData).then(resp=>{
            this.isSaving = false
            if(resp.data.status == "ok"){
              this.$router.push({name:'auth-login-partner'})
            }
          })
/*           useJwt.register({
            username: this.username,
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              this.$ability.update(response.data.userData.ability)
              this.$router.push('/')
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            }) */
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
